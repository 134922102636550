<template>

    <div class="contenedor-paridad-app">
        <div class="modal-inicio">
            <div class="icono-contenedor">
                <EqualityGender nombre="logotipo"/>
            </div> 
            <div class="texto-modal-inicio">
                <hr>
                <p>El Sistema Monitor de Igualdad, es una herramienta digital de carácter público, el cual tiene como propósito fortalecer a las Instituciones integrantes del Observatorio de Participación Política de las Mujeres del Estado de Morelos y al efecto, visibilizar la representación y participación de las mujeres dentro de los 36 Ayuntamientos y su Congreso Local</p>
                <p>El presente sistema permite visibilizar las brechas de desigualdad entre mujeres y hombres en los puestos de toma de decisión en dos momentos; durante las candidaturas a puestos de elección popular y durante el ejercicio del cargo.</p>
                <p>La información públicada es de carácter institucional participando las instituciones integrantes del Observatorio de Participación Política de las Mujeres en el Estado de Morelos.</p>
                <p>En la presente plataforma podrás conocer indicadores, como el número de hombres y mujeres postuladas en las diputaciones y presidencias municipales; la proporción de hombres y muejeres gobernando los municipios de mayor población y por ende de mayor recurso; la comparación de los principales órganos internos del Ayuntamiento y el Congreso Local, así como la participación de las mujeres en las Sesiones de Cabildo y en las Sesiones del Congreso.</p>
            </div>
             
        </div>
        <div class="contenedor-elementos">
           
                <div class="contenedor-diputaciones">
                    <div class="contenedor-iconos">
                        <Iconos nombre="parlamento"/>
                    </div>
                    <h1 @click="goDiputaciones">Diputaciones</h1>
                    <p>Información sobre los cargos de elección popular en diputaciones locales en Morelos</p>
                </div>
            

           
                <div class="contenedor-diputaciones">
                    <div class="contenedor-iconos">
                        <MapaGeneral nombre="MapaGeneral"/>
                    </div>
                        <h1 @click="goMunicipios">Municipios</h1>
                        <p>Información sobre los cargos de elección popular en los municipios en Morelos</p>
                </div>
            
           
            
        </div>
    </div>


</template>

<script>

import Iconos from '../components/Iconos.vue'
import MapaGeneral from '../components/Iconos.vue'
import EqualityGender from '../components/Iconos.vue'

export default {

    components: {
        Iconos,
        MapaGeneral,
        EqualityGender
    },
    methods: {
      goDiputaciones() {
        this.$router.push( { name: 'home-diputaciones' })
      },
      goMunicipios() {
        this.$router.push( { name: 'home-municipios' })
      }
    }


}
</script>

<style lang="scss" scoped>

    .contenedor-paridad-app{
        width:100%;
        text-align:center;
    }
    @media screen and (max-width: 600px) {

    .contenedor-elementos{
        width:90%;
        margin:10px auto;
        display:flex;
        flex-wrap: wrap;
        
    }

    /* Texto inicio */
    .texto-modal-inicio{
        width:90%;
        margin:10px auto;
        text-align: justify;
    }

    .contenedor-diputaciones{
        width:100%;
        cursor:pointer;
        background-color:#392f5c;
        margin-bottom:20px;
    }
    .icono-contenedor{
        width:90%;
        margin:10px auto;
        height:200px;
    }
    .contenedor-iconos{
        width:50%;
        padding:10px 10px;
        margin:20px auto;
    }
    .contenedor-diputaciones:hover{
        background-color:black;
        color:#fff;
    }
    .contenedor-diputaciones h1,a{
        margin:0;
        text-decoration:none;
        text-transform: uppercase;
        color: #fff;
    }
    .contenedor-diputaciones p{
        padding:10px 12px;
        font-size:12px;
        width:80%;
        margin:20px auto;
        cursor:pointer;
        color: #fff;
        
    }
    
    
    }

    @media screen and (min-width: 900px) {
        .contenedor-elementos{
            width:60%;
            margin:10px auto;
            display: flex; align-items:center; justify-content:space-between;
            padding-bottom:100px;
        }
        .contenedor-diputaciones{
            width:48%;
            margin:10px auto;
            height:400px;
            display: flex; flex-direction:column; align-items:center; justify-content:center;
            background-color: #392f5c;
            color: #fff;
        }
        .contenedor-diputaciones a{
            color: #fff;
            text-decoration:none;
            margin:0;
            text-transform: uppercase;
        }
        .contenedor-diputaciones a:hover{
            text-decoration: underline;
        } 
        .contenedor-diputaciones h1{
            margin:0;
            text-transform:uppercase;
            font-size:28px;
            font-weight: bold;
            text-decoration:underline;
            cursor:pointer;
        }  
         .contenedor-diputaciones p{
            width:80%;
            margin:10px auto;
        }     
        .contenedor-iconos{
            width:40%;

        }
        .modal-inicio{
            width: 60%;
            margin:10px auto;
            text-align:justify;
            color: #392f5c;

        }
        .modal-inicio h3{
            font-size:28px;
            margin:0;
            text-align:center;
        }
        .modal-inicio p{
            font-size:20px;
            margin:50px auto;
            color: #392f5c;
            font-weight: 600;
        }
        .icono-contenedor{
           width:60%;
           margin:70px auto;
        }
    }


</style>